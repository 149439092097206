import { useState } from "react";
import { FlexDiv } from "./FlexDiv";
import colors from "../colors";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const ExpandableList = props => {
  const { label, listItems, style } = props;
  const [open, setOpen] = useState(false);
  return (
    <div style={{ color: "black", ...style }}>
      <FlexDiv
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer", marginBottom: 10 }}
      >
        {open ? <FiChevronUp /> : <FiChevronDown />}
        <span style={{ fontSize: 14, marginLeft: 10 }}>
          {label} ({listItems.length})
        </span>
      </FlexDiv>
      <div
        style={{
          height: open ? "100%" : 0,
          overflow: "hidden"
        }}
      >
        {listItems.map((l, i) => (
          <div
            key={l.appName}
            style={{
              backgroundColor:
                i % 2 === 0 ? colors.alternatingRowGrey : "white",
              padding: "8px 5px",
              fontSize: 14
            }}
          >
            {l.appName}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpandableList;
