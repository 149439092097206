import colors from "../../colors";
import Colors from "../../colors";
import { ConfirmButton } from "../../components/Buttons";
import { FlexDiv } from "../../components/FlexDiv";

const TileSectionTitle = ({ text }) => (
  <div style={{ fontSize: 14, color: Colors.mediumGrey, marginBottom: 5 }}>
    {text}
  </div>
);
const BundleName = props => {
  const { bundleName } = props;
  return (
    <div style={{ marginBottom: 16 }}>
      <TileSectionTitle text="Bundle Name" />
      <div style={{ fontSize: 18, fontWeight: 600 }}>{bundleName}</div>
    </div>
  );
};

const Endpoints = props => {
  const { endpoints } = props;
  const endpointArray = endpoints.map(endpoint => {
    const urlKey = Object.keys(endpoint).find(k => k.includes("URL"));
    return endpoint[urlKey];
  });

  return (
    <div>
      <TileSectionTitle text="Endpoint URL(s)" />
      <div style={{ overflowWrap: "break-word" }}>{endpointArray[0]}</div>
      {endpointArray.length > 1 && (
        <div style={{ color: Colors.blue }}>
          +{endpointArray.length - 1} more
        </div>
      )}
    </div>
  );
};

const BundleTile = props => {
  const { bundle, style, onClick, selected } = props;
  const endpoints = bundle.endpoints.map(endpointObject => {
    const endpointKeys = Object.keys(endpointObject).filter(
      k => !["id", "Doc Path"].includes(k)
    );
    return endpointKeys.map(k => ({
      [k]: endpointObject[k]
    }))[0];
  });
  return (
    <FlexDiv
      style={{
        flexDirection: "column",
        height: 210,
        width: 270,
        borderRadius: 4,
        boxShadow: selected
          ? `0px 0px 5px 0px ${colors.blue}`
          : "0 2px 4px 0 rgba(0,0,0,0.12)",
        padding: "16px 24px",
        backgroundColor: "white",
        border: selected ? `2px solid ${colors.blue}` : "2px solid white",
        ...style
      }}
    >
      <BundleName bundleName={bundle.name} />
      <Endpoints endpoints={endpoints} />
      <FlexDiv style={{ flexGrow: 1, alignItems: "flex-end" }}>
        <ConfirmButton
          label="View Details"
          onClick={onClick}
          inverted
          style={{ flexGrow: 1, fontSize: 14, height: 32 }}
        />
      </FlexDiv>
    </FlexDiv>
  );
};

export default BundleTile;
