import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UsersState from "../users/UsersState";
import ColoredLogo from "../../assets/colored-logo.svg";
import CompanySelector from "../../components/CompanySelector";
import { FiLogOut } from "react-icons/fi";
import _ from "lodash";
import "./appNavBar.scss";

export const PageKeys = {
  INTRODUCTION: {
    key: "INTRODUCTION",
    title: "Introduction",
    path: "/introduction"
  },
  KEY_MANAGEMENT: {
    key: "KEY_MANAGEMENT",
    title: "API Management",
    path: "/api-key-management"
  },
  API_STATISTICS: {
    key: "API_STATISTICS",
    title: "API Statistics",
    path: "/api-statistics"
  }
};

const UserControl = () => {
  const currentUser = useSelector(UsersState.selectors.getCurrentUser);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(UsersState.actionCreators.userLogout());
  };

  const getDisplayName = () => {
    if (!!currentUser) {
      const { firstName, lastName } = currentUser;
      let nameSymbol = null;
      if (!!firstName) {
        nameSymbol = _.upperCase(firstName[0]);
      }
      if (!!firstName && !!lastName) {
        nameSymbol = nameSymbol.concat(_.upperCase(lastName[0]));
      }
      return (
        <div className="user-control">
          <div className="user-control__logout" onClick={logoutHandler}>
            <FiLogOut size={24} />
          </div>
          <div className="user-control__name">{nameSymbol}</div>
        </div>
      );
    }
    return null;
  };
  return <div className="user-control__name-container">{getDisplayName()}</div>;
};

const NavButton = props => {
  const { pageKey, selected } = props;
  const page = PageKeys[pageKey];
  const title = page.title;
  return (
    <Link to={page.path} className="nav-button">
      <div
        className={
          selected ? "nav-button__label--selected" : "nav-button__label"
        }
      >
        <span>{title}</span>
      </div>
    </Link>
  );
};

const AppNavBar = props => {
  const { selectedPage } = props;
  const dispatch = useDispatch();
  const onSearchHandler = query => {
    dispatch(UsersState.actionCreators.fetchCompanies(query));
  };
  const selectedCompany = useSelector(UsersState.selectors.getSelectedCompany);
  const selectedCompanyId = useSelector(
    UsersState.selectors.getSelectedCompanyId
  );
  const setCompanyHandler = company => {
    dispatch(UsersState.actionCreators.setSelectedCompany(company));
  };

  const clearCompanyHandler = () => {
    dispatch(UsersState.actionCreators.clearSelectedCompany());
  };

  const isSuperAdmin = useSelector(UsersState.selectors.getIsSuperAdmin);
  const isLoading = useSelector(UsersState.selectors.getAutocompleteLoading);
  const options = useSelector(UsersState.selectors.getCompanies);

  return (
    <div className="app-nav">
      <div className="app-nav__top">
        <div className="app-nav__logo">
          <img src={ColoredLogo} alt="FourKites" />
        </div>
        {isSuperAdmin && (
          <CompanySelector
            key={selectedCompanyId}
            onSubmit={setCompanyHandler}
            onSearch={onSearchHandler}
            isLoading={isLoading}
            options={options}
            defaultSelected={selectedCompanyId ? [selectedCompany] : []}
            clearCompany={clearCompanyHandler}
          />
        )}
        <UserControl />
      </div>
      <div className="app-nav__bottom">
        <NavButton
          pageKey={PageKeys.INTRODUCTION.key}
          selected={selectedPage === PageKeys.INTRODUCTION.key}
        />
        {selectedCompanyId && (
          <NavButton
            pageKey={PageKeys.KEY_MANAGEMENT.key}
            selected={selectedPage === PageKeys.KEY_MANAGEMENT.key}
          />
        )}
      </div>
    </div>
  );
};

export default AppNavBar;
