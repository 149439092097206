import { useDispatch, useSelector } from "react-redux";
import { formatTs } from "./EditApplicationRecordForm";
import { InfoField, StaticField } from "../../components/TextInput";
import { SlideToggle } from "../../components/Buttons";
import ApiBundles from "./ApiBundles";
import ApiKeyManagementState from "./ApiKeyManagementState";

const ApplicationDetailsForm = props => {
  const { applicationDetails } = props;
  const {
    appName,
    ipAddress,
    apiKey,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    apiBundles
  } = applicationDetails;
  const applicationData = useSelector(
    ApiKeyManagementState.selectors.getApplicationList
  );
  const selectedApplication = applicationData.filter(
    d => d.apiKey === apiKey
  )[0];

  const active = selectedApplication?.active;

  const selectedBundles = apiBundles.map(b => b.id);
  const dispatch = useDispatch();
  const updateStatusHandler = (apiKey, status) => {
    dispatch(
      ApiKeyManagementState.actionCreators.updateApplication(apiKey, {
        active: status
      })
    );
  };
  return (
    <div>
      <InfoField label="App Name" text={appName} />
      <InfoField label="IP Address" text={ipAddress || "None Specified"} />
      <InfoField label="API Key" text={apiKey} clickToCopy />
      <StaticField label="Key Status" style={{ margin: "20px 0" }}>
        <SlideToggle
          active={active}
          onChange={() => {
            updateStatusHandler(apiKey, !active);
          }}
        />
      </StaticField>
      <ApiBundles
        style={{ color: "grey" }}
        apiBundles={selectedBundles || []}
        disabled
      />
      <InfoField label="Created On" text={formatTs(createdAt)} />
      <InfoField label="Created By" text={createdBy} />
      <InfoField label="Last Modified On" text={formatTs(updatedAt)} />
      <InfoField label="Last Modified By" text={updatedBy} />
    </div>
  );
};

export default ApplicationDetailsForm;
