import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import Colors from "../colors";

const defaultSize = 24;
const Checkbox = props => {
  const { isSelected, onClick, size, disabled } = props;
  const icon = isSelected ? (
    <MdCheckBox size={size ? size : defaultSize} />
  ) : (
    <MdCheckBoxOutlineBlank size={size} />
  );
  return (
    <span
      onClick={disabled ? null : onClick}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled ? "#d2d2d2" : Colors.blue,
        display: "flex",
        alignItems: "center"
      }}
    >
      {icon}
    </span>
  );
};

export default Checkbox;
