import { useEffect } from "react";
import {
  StaticField,
  ClickToCopyField,
  InfoField,
  ValidatedTextInput
} from "../../components/TextInput";
import { SlideToggle } from "../../components/Buttons";
import ApiBundles from "./ApiBundles";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import { useDispatch, useSelector } from "react-redux";
import ApiKeyManagementState from "./ApiKeyManagementState";
import HoverIcon from "../../components/HoverIcon";
import { ipAddressInfo, validateIpAddress } from "./NewApiKeyForm";
import _ from "lodash";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

export const formatTs = ts => {
  if (!ts) {
    return "";
  }

  const localTzGuess = dayjs.tz.guess();
  const dayObj = dayjs(ts);
  if (["Asia/Calcutta", "Asia/Kolkata"].includes(localTzGuess)) {
    return `${dayObj.format("MMMM D, YYYY HH:mm")} IST`;
  }
  return dayObj.format("MMMM D, YYYY HH:mm z");
};

const EditApplicationRecordForm = props => {
  const { applicationDetails } = props;
  const {
    appName,
    apiKey,
    apiBundles,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy
  } = applicationDetails;
  const applicationData = useSelector(
    ApiKeyManagementState.selectors.getApplicationList
  );
  const selectedApplication = applicationData.filter(
    d => d.apiKey === apiKey
  )[0];

  const active = selectedApplication?.active;
  let { ipAddress } = applicationDetails;
  if (!ipAddress) {
    ipAddress = "";
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      ApiKeyManagementState.actionCreators.updateFormData({
        appName,
        ipAddress,
        active,
        apiBundles: apiBundles.map(b => b.id)
      })
    );
    // eslint-disable-next-line
  }, [dispatch]);
  const appData = useSelector(ApiKeyManagementState.selectors.getFormData);

  const updateFormData = (key, value) => {
    dispatch(
      ApiKeyManagementState.actionCreators.updateFormData({
        ...appData,
        [key]: value
      })
    );
  };

  const hasDataChanged = updatedFormData => {
    let appNameChanged = false;
    let ipAddressChanged = false;
    let activeStatusChanged = false;
    let apiBundlesChanged = false;
    if (!_.isEqual(appName, updatedFormData.appName)) {
      appNameChanged = true;
    }
    if (!_.isEqual(ipAddress, updatedFormData.ipAddress)) {
      ipAddressChanged = true;
    }
    if (!_.isEqual(active, updatedFormData.active)) {
      activeStatusChanged = true;
    }
    if (
      !_.isEqual(
        _.sortBy(apiBundles.map(b => b.id)),
        _.sortBy(updatedFormData.apiBundles)
      )
    ) {
      apiBundlesChanged = true;
    }
    return (
      appNameChanged ||
      ipAddressChanged ||
      activeStatusChanged ||
      apiBundlesChanged
    );
  };
  useEffect(() => {
    // check form to enable or disable save
    // 1. appName must be populated
    // 2. at least one bundle must be selected
    let allowSave = false;
    if (
      appData.appName.length >= 6 &&
      appData.apiBundles.length > 0 &&
      validateIpAddress(appData.ipAddress)
    ) {
      allowSave = true;
    }
    // disable save if data hasn't changed
    if (allowSave && !hasDataChanged(appData)) {
      allowSave = false;
    }
    dispatch(ApiKeyManagementState.actionCreators.enableSave(allowSave));
    // eslint-disable-next-line
  }, [dispatch, appData]);
  return (
    <div>
      <ValidatedTextInput
        label={"App Name"}
        value={appData.appName}
        placeholder="Application Name"
        onChange={appName => updateFormData("appName", appName)}
        style={{ marginBottom: 20 }}
        required
        validateCheck={appData?.appName?.length >= 6}
        errorMessage={"App name should have a minimum of 6 characters"}
      />
      <ValidatedTextInput
        label={"IP Address"}
        placeholder="1.1.1.1 (or IPv6)"
        value={!!appData.ipAddress ? appData.ipAddress : ""}
        onChange={ipAddress => updateFormData("ipAddress", ipAddress)}
        style={{ marginBottom: 28, fontSize: 14 }}
        validateCheck={validateIpAddress(appData?.ipAddress)}
        errorMessage={"Enter a valid IPv4 or IPv6 address"}
        hoverIcon={
          <HoverIcon
            hoverText={ipAddressInfo}
            hoverStyle={{
              bottom: 30,
              right: 0
            }}
          />
        }
      />
      <ClickToCopyField
        label={"API Key"}
        value={apiKey}
        style={{ marginBottom: 20 }}
      />
      <StaticField label="Key Status" style={{ margin: "20px 0" }}>
        <SlideToggle
          key={appData.active}
          active={appData.active}
          onChange={() => {
            updateFormData("active", !appData.active);
          }}
        />
      </StaticField>
      <ApiBundles
        style={{ color: "grey" }}
        apiBundles={appData.apiBundles || []}
        updateSelectedBundles={newSelectedBundleIds =>
          updateFormData("apiBundles", newSelectedBundleIds)
        }
      />
      <InfoField label="Created On" text={formatTs(createdAt)} />
      <InfoField label="Created By" text={createdBy} />
      <InfoField label="Last Modified On" text={formatTs(updatedAt)} />
      <InfoField label="Last Modified By" text={updatedBy} />
    </div>
  );
};

export default EditApplicationRecordForm;
