import axios from "axios";
import UsersState, { cookies } from "../users/UsersState";

/* eslint-disable import/no-anonymous-default-export */
const STORE_MOUNT_POINT = "apiKeyManagement";

// ACTIONS
const FETCH_APPLICATION_LIST = "ApiKeyManagement/FETCH_APPLICATION_LIST";
const FETCH_API_BUNDLES = "ApiKeyManagement/FETCH_API_BUNDLES";
const UPDATE_FORM_DATA = "ApiKeyManagement/UPDATE_FORM_DATA";
const LOADING = "ApiKeyManagement/LOADING";
const API_REQUEST_SUCCESS = "ApiKeyManagement/API_REQUEST_SUCCESS";
const API_REQUEST_ERROR = "ApiKeyManagement/API_REQUEST_ERROR";
const CLEAR_MESSAGE = "ApiKeyManagement/CLEAR_MESSAGE";
const ENABLE_SAVE = "ApiKeyManagement/ENABLE_SAVE";

// BUNDLE KEYS
const SHIPMENT_API = "shipment_api";
const LOAD_API = "load_api";
const PO_API = "po_api";
const CARRIER_API = "carrier_api";

// BASE URL
const baseUrl = `https://${window.devPortalConfig.USER_SERVICE_HOST}/api/v1`;

// UTILITIES
const getCookies = () => {
  const authToken = cookies.get("auth-token");
  const userId = cookies.get("user-id");
  const deviceId = cookies.get("device-id");
  return {
    authToken,
    userId,
    deviceId
  };
};

// ACTION CREATORS
const fetchApplicationList = () => {
  const url = `${baseUrl}/api_consumers`;
  return async (dispatch, getState) => {
    dispatch({ type: LOADING });
    const { authToken, deviceId, userId } = getCookies();
    let resp = await axios.get(url, {
      params: {
        companyId: getState().users.companyId
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-FourKitesUserId": userId,
        "X-FourKitesDeviceId": deviceId
      }
    });
    dispatch({ type: FETCH_APPLICATION_LIST, payload: resp.data.apiConsumers });
  };
};

const fetchApiBundles = () => {
  const url = `${baseUrl}/api_consumers/bundles`;
  return async (dispatch, getState) => {
    const { authToken, deviceId, userId } = getCookies();
    let resp = await axios.get(url, {
      params: {
        companyId: getState().users.companyId
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-FourKitesUserId": userId,
        "X-FourKitesDeviceId": deviceId
      }
    });
    dispatch({ type: FETCH_API_BUNDLES, payload: resp.data.apiBundles });
  };
};

const updateApplication = (apiKey, payload) => {
  const url = `${baseUrl}/api_consumers/${apiKey}`;
  return async (dispatch, getState) => {
    const { authToken, deviceId, userId } = getCookies();
    try {
      const resp = await axios.put(
        url,
        {
          ...payload,
          companyId: getState().users.companyId
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-FourKitesUserId": userId,
            "X-FourKitesDeviceId": deviceId
          }
        }
      );
      dispatch({
        type: API_REQUEST_SUCCESS,
        payload: resp.data.message
      });
      dispatch(fetchApplicationList());
      dispatch(fetchApiBundles());
    } catch (error) {
      if (error.response.status === 403) {
        dispatch(UsersState.actionCreators.userLogout());
      } else {
        dispatch({
          type: API_REQUEST_ERROR,
          payload: getErrorText(error)
        });
      }
    }
  };
};

const createNewApiKey = payload => {
  const url = `${baseUrl}/api_consumers`;
  return async (dispatch, getState) => {
    const { authToken, deviceId, userId } = getCookies();
    try {
      const resp = await axios.post(
        url,
        {
          ...payload,
          companyId: getState().users.companyId
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-FourKitesUserId": userId,
            "X-FourKitesDeviceId": deviceId
          }
        }
      );
      dispatch({ type: API_REQUEST_SUCCESS, payload: resp.data.message });
      dispatch(fetchApplicationList());
      dispatch(fetchApiBundles());
    } catch (error) {
      if (error.response.status === 403) {
        dispatch(UsersState.actionCreators.userLogout());
      } else {
        dispatch({
          type: API_REQUEST_ERROR,
          payload: getErrorText(error)
        });
      }
    }
  };
};

const deleteApiConsumer = apiKey => {
  const url = `${baseUrl}/api_consumers/${apiKey}`;
  return async (dispatch, getState) => {
    const { authToken, deviceId, userId } = getCookies();
    try {
      const resp = await axios.delete(url, {
        data: {
          companyId: getState().users.companyId
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-FourKitesUserId": userId,
          "X-FourKitesDeviceId": deviceId
        }
      });
      dispatch({
        type: API_REQUEST_SUCCESS,
        payload: resp.data.message
      });
      dispatch(fetchApplicationList());
      dispatch(fetchApiBundles());
    } catch (error) {
      if (error.response.status === 403) {
        dispatch(UsersState.actionCreators.userLogout());
      } else {
        dispatch({
          type: API_REQUEST_ERROR,
          payload: getErrorText(error)
        });
      }
    }
  };
};

const updateFormData = data => {
  return { type: UPDATE_FORM_DATA, payload: data };
};

const clearMessage = () => {
  return { type: CLEAR_MESSAGE };
};

const enableSave = allowSave => {
  return { type: ENABLE_SAVE, payload: allowSave };
};

const getErrorText = error => {
  let text = "An error has occurred";
  const data = error.response?.data;

  // JavaScript error object
  if (error.message) {
    text = error.message;
  }

  // Fetch response includes 'error' property
  if (data?.error) {
    text = data.error;
  }

  // Fetch response includes 'errors' array
  if (data?.errors?.length) {
    text = data.errors[0];
  }

  return text;
};

// INITIAL STATE
const initialState = {
  applicationList: null,
  apiBundles: null,
  formData: {
    appName: "",
    ipAddress: "",
    apiKey: ""
  },
  loading: false,
  message: null,
  enableSave: false
};

// REDUCER
const ApiKeyManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case FETCH_APPLICATION_LIST:
      return {
        ...state,
        applicationList: action.payload,
        loading: false
      };
    case FETCH_API_BUNDLES:
      return {
        ...state,
        apiBundles: action.payload
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: action.payload
      };
    case ENABLE_SAVE:
      return {
        ...state,
        enableSave: action.payload
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null
      };
    case API_REQUEST_SUCCESS:
      return {
        ...state,
        message: {
          text: action.payload,
          messageType: "success"
        }
      };
    case API_REQUEST_ERROR:
      return {
        ...state,
        message: { text: action.payload, messageType: "error" }
      };
    default:
      return state;
  }
};

// SELECTORS
const getApplicationList = state => state[STORE_MOUNT_POINT].applicationList;
const getApiBundles = state => state[STORE_MOUNT_POINT].apiBundles;
const getFormData = state => state[STORE_MOUNT_POINT].formData;
const getLoadingStatus = state => state[STORE_MOUNT_POINT].loading;
const getMessage = state => state[STORE_MOUNT_POINT].message;
const getEnableSaveStatus = state => state[STORE_MOUNT_POINT].enableSave;

export default {
  mountPoint: STORE_MOUNT_POINT,
  keys: {
    SHIPMENT_API,
    CARRIER_API,
    LOAD_API,
    PO_API
  },
  actions: {
    FETCH_APPLICATION_LIST,
    FETCH_API_BUNDLES,
    UPDATE_FORM_DATA
  },
  actionCreators: {
    fetchApplicationList,
    fetchApiBundles,
    updateApplication,
    updateFormData,
    createNewApiKey,
    deleteApiConsumer,
    clearMessage,
    enableSave
  },
  selectors: {
    getApplicationList,
    getApiBundles,
    getFormData,
    getLoadingStatus,
    getMessage,
    getEnableSaveStatus
  },
  reducer: ApiKeyManagementReducer
};
