import SectionTitle from "./SectionTitle";
import _ from "lodash";
import { FlexDiv } from "../../components/FlexDiv";
import { ConfirmButton } from "../../components/Buttons";
import { FiArrowRight } from "react-icons/fi";
import ComputerGeek from "../../assets/computer-geek.svg";
import UsersState from "../users/UsersState";
import { useSelector, useDispatch } from "react-redux";

const archbeeUrl = `https://docs.fourkites.com/api-reference`;

const WelcomeSection = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(UsersState.selectors.getCurrentUser);
  const { firstName } = currentUser;
  const windowOpenHandler = async () => {
    try {
      const archbeeJwt = await dispatch(UsersState.helpers.getArchbeeJwt());
      const jwtAddedUrl = `${archbeeUrl}?jwt=${archbeeJwt}`;
      const win = window.open(jwtAddedUrl, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Please allow popus for this website");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <FlexDiv
      style={{
        paddingLeft: 137,
        paddingTop: 73,
        paddingRight: 168,
        boxSizing: "border-box"
      }}
    >
      <div style={{ width: "50%", flexShrink: 0 }}>
        <img
          src={ComputerGeek}
          alt="Computer Geek"
          style={{
            objectFit: "contain",
            width: "100%"
          }}
        />
      </div>
      <div
        style={{
          paddingLeft: 131
        }}
      >
        {firstName && (
          <SectionTitle text={`Hey There, ${_.upperFirst(firstName)}!`} />
        )}
        <div>
          <p style={{ marginTop: 20, marginBottom: 24 }}>
            Welcome to FourKites' Developer Portal
          </p>
          <div>
            <ul style={{ paddingLeft: 16 }}>
              <li>
                We support shippers, partners, and carriers in creating
                innovative technology solutions with our APIs, SDKs, and data
                products.
              </li>
              <li style={{ marginTop: 12 }}>
                Whether you're looking for status information on your shipment,
                integrating your TMS with our platform or building the next
                generation of supply chain applications, we have you covered.
              </li>
              <li style={{ marginTop: 12 }}>
                Get started with our API documentation and manage endpoint
                access with our key management module.
              </li>
            </ul>
          </div>
          <p style={{ marginTop: 24 }}>
            Join us in ushering in the era of digital supply chain management!
          </p>
          <ConfirmButton
            onClick={windowOpenHandler}
            style={{ width: 285, marginTop: 4 }}
            label={
              <FlexDiv style={{ alignItems: "center" }}>
                <span>Go to the API Documentation</span>
                <FiArrowRight size={18} style={{ marginLeft: 5 }} />
              </FlexDiv>
            }
          />
        </div>
      </div>
    </FlexDiv>
  );
};

export default WelcomeSection;
