import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { ConfirmButton } from "../../components/Buttons";
import Checkbox from "../../components/Checkbox";
import TextInput from "../../components/TextInput";
import Hyperlink from "../../components/Hyperlink";
import UsersState from "../users/UsersState";
import ColoredLogo from "../../assets/colored-logo.svg";
import colors from "../../colors";

const FourKitesLogo = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 35
      }}
    >
      <img
        src={ColoredLogo}
        alt="FourKites"
        style={{ objectFit: "contain", width: 185 }}
      />
    </div>
  );
};

const FormDescription = ({ isLoginPage }) => {
  const message = isLoginPage
    ? "Sign in to get started"
    : "Please fill in the form and one of our support executives will get in touch with you";
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: 18,
        marginBottom: 32
      }}
    >
      {message}
    </div>
  );
};

const FormTypeMessage = ({ isLoginPage }) => {
  const message = isLoginPage
    ? "Welcome to FourKites!"
    : "Request Access to FourKites' Developer Portal";
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: 28,
        fontWeight: 100,
        marginBottom: 16
      }}
    >
      {message}
    </div>
  );
};

const footerStyle = {
  marginTop: 140
};

// const LoginFooterText = props => {
//   const { onClick } = props;
//   return (
//     <div
//       style={{
//         ...footerStyle,
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center"
//       }}
//     >
//       <div>Don't have an account yet?</div>
//       <Hyperlink
//         text={"Request access to view all our APIs"}
//         onClick={onClick}
//       />
//     </div>
//   );
// };

const RegistrationFooterText = props => {
  const { onClick } = props;
  return (
    <div style={{ ...footerStyle, display: "flex", justifyContent: "center" }}>
      <span style={{ marginRight: 2 }}>Already have an account?</span>
      <Hyperlink onClick={onClick} text="Sign in" />
    </div>
  );
};

const formContainerStyle = {
  width: 500,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "0 50px",
  boxSizing: "border-box",
  backgroundColor: "rgb(243, 247, 252)"
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const secretKey = window.devPortalConfig.SECRET_KEY;

export const LoginForm = props => {
  const [isSelected, setIsSelected] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disableLogin, setDisableLogin] = useState(true);
  const dispatch = useDispatch();
  const error = useSelector(UsersState.selectors.getAuthError);
  const prevIsSelected = usePrevious(isSelected);

  useEffect(() => {
    const validUsername = username.length > 0;
    const validPassword = password.length > 0;

    if (validUsername && validPassword) {
      setDisableLogin(false);
    } else {
      setDisableLogin(true);
    }
  }, [username, password]);

  useEffect(() => {
    const storedCredentials = JSON.parse(localStorage.getItem("devPortal"));
    if (storedCredentials) {
      // decrypt password
      const bytes = CryptoJS.AES.decrypt(storedCredentials.password, secretKey);
      const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
      setUsername(storedCredentials.username);
      setPassword(decryptedPassword);
      setIsSelected(true);
    }
  }, []);

  useEffect(() => {
    if (!isSelected && prevIsSelected) {
      localStorage.removeItem("devPortal");
    }
    // eslint-disable-next-line
  }, [isSelected]);

  const loginHandler = e => {
    e.preventDefault();
    if (isSelected) {
      // encrypt password
      const encryptedPassword = CryptoJS.AES.encrypt(
        password,
        secretKey
      ).toString();
      localStorage.setItem(
        "devPortal",
        JSON.stringify({ username, password: encryptedPassword })
      );
    }
    dispatch(UsersState.actionCreators.userLogin(username, password));
  };
  return (
    <form onSubmit={loginHandler} style={formContainerStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1
        }}
      >
        <FourKitesLogo />
        <FormTypeMessage isLoginPage />
        <FormDescription isLoginPage />
        <div
          style={{
            color: colors.red,
            textAlign: "center",
            height: 30,
            opacity: error ? 1 : 0
          }}
        >
          {error}
        </div>
        <TextInput
          placeholder="john.doe@wayneenterprises.com"
          label="Email"
          value={username}
          onChange={setUsername}
          style={{ marginBottom: 16 }}
        />
        <TextInput
          placeholder="Ssh! This is a secret"
          label="Password"
          type="password"
          style={{ marginBottom: 16 }}
          value={password}
          onChange={setPassword}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            size={24}
            isSelected={isSelected}
            onClick={() => setIsSelected(!isSelected)}
          />
          <span
            style={{
              fontSize: 13,
              marginLeft: 2,
              display: "flex",
              alignItems: "center"
            }}
          >
            Remember me on this device
          </span>
        </div>
        <div>
          <ConfirmButton
            label="Sign In"
            style={{ marginTop: 32, width: "100%" }}
            disable={disableLogin}
          />
        </div>
        {/* <div style={{ textAlign: "center", marginTop: 30, fontSize: 12 }}>
          Version 0.0.1
        </div> */}
      </div>
    </form>
  );
};

export const RegistrationForm = props => {
  const { changeFormHandler } = props;
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div style={formContainerStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1
        }}
      >
        <FourKitesLogo />
        <FormTypeMessage />
        <FormDescription />
        <TextInput
          placeholder="John Doe"
          label="Full Name"
          style={{ marginBottom: 16 }}
        />
        <TextInput
          placeholder="john.doe@wayneenterprises.com"
          label="Email"
          style={{ marginBottom: 16 }}
        />
        <TextInput
          placeholder="+1 968 842 4615"
          label="Contact Number (Optional)"
          type="password"
          style={{ marginBottom: 16 }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            size={24}
            isSelected={isSelected}
            onClick={() => setIsSelected(!isSelected)}
          />
          <span
            style={{
              fontSize: 16,
              marginLeft: 2,
              display: "flex",
              alignItems: "center"
            }}
          >
            <span style={{ marginRight: 3 }}>I agree to FourKites'</span>
            <Hyperlink onClick={null} text="privacy terms & conditions" />
          </span>
        </div>
        <div style={{ marginTop: 32, marginRight: 50 }}>
          <ConfirmButton
            label="Sign In"
            onClick={null}
            style={{ width: "100%" }}
          />
        </div>
        <RegistrationFooterText onClick={changeFormHandler} />
      </div>
    </div>
  );
};
