import { useSelector } from "react-redux";
import ApiKeyManagementState from "./ApiKeyManagementState";
import {
  StaticField,
  ClickToCopyField,
  NavigationFieldLabel
} from "../../components/TextInput";
import { FlexDiv } from "../../components/FlexDiv";
import ExpandableList from "../../components/ExpandableList";
import HoverIcon from "../../components/HoverIcon";
import colors from "../../colors";
import { formatTs } from "./EditApplicationRecordForm";

const { getApplicationList } = ApiKeyManagementState.selectors;

const endpointHoverInfo =
  "Incoming API requests to FourKites are sent to these corresponding URLs";

const BundleDetailsForm = props => {
  const applications = useSelector(getApplicationList);

  const { bundle } = props;
  const { name, subscribedOn, endpoints } = bundle;
  const bundleApplications = applications.filter(a =>
    a.apiBundles.includes(bundle.id)
  );
  const activeItems = bundleApplications.filter(a => a.active);
  const inactiveItems = bundleApplications.filter(a => !a.active);

  const formattedEndpoints = endpoints.map(endpoint => {
    const urlKey = Object.keys(endpoint).find(k => k.includes("URL"));
    return {
      name: urlKey,
      url: endpoint[urlKey],
      docPath: endpoint["Doc Path"]
    };
  });

  return (
    <div>
      <StaticField label={"Bundle Name"} style={{ color: colors.mediumGrey }}>
        <span
          style={{ fontSize: 18, fontWeight: 600, color: colors.textBlack }}
        >
          {name}
        </span>
      </StaticField>
      <StaticField
        label={"Subscribed On"}
        style={{ color: colors.mediumGrey, marginTop: 24 }}
      >
        <span style={{ color: colors.textBlack }}>
          {formatTs(subscribedOn)}
        </span>
      </StaticField>
      <StaticField
        label={
          <FlexDiv style={{ alignItems: "center", marginTop: 24 }}>
            <span style={{ color: colors.mediumGrey }}>Endpoint URL(s)</span>
            <HoverIcon
              iconSize={16}
              hoverText={endpointHoverInfo}
              style={{ marginLeft: 5, color: colors.mediumGrey }}
            />
          </FlexDiv>
        }
      >
        {formattedEndpoints.map((e, i) => (
          <ClickToCopyField
            key={e.name}
            label={<NavigationFieldLabel label={e.name} navPath={e.docPath} />}
            value={e.url}
            hoverText={"Click to Copy"}
            hoverStyle={{ right: -10, width: 100, top: -40 }}
            showFieldOnHover
          />
        ))}
      </StaticField>
      <StaticField
        label={"Linked Application"}
        style={{ marginTop: 20, color: "grey" }}
      >
        <ExpandableList
          label={"Active"}
          listItems={activeItems || []}
          style={{ marginTop: 10 }}
        />
        <ExpandableList
          label={"Inactive"}
          listItems={inactiveItems || []}
          style={{ marginTop: 15 }}
        />
      </StaticField>
    </div>
  );
};

export default BundleDetailsForm;
