import Colors from "../../colors";

const StatBox = props => {
  const { headerText, bodyText } = props;
  return (
    <div style={{ textAlign: "center", width: "22%" }}>
      <div
        style={{
          fontWeight: 600,
          color: Colors.dijonYellow,
          marginBottom: 6
        }}
      >
        {headerText}
      </div>
      <div style={{ fontSize: 13, color: "white" }}>{bodyText}</div>
    </div>
  );
};

const CustomerCarousel = () => {
  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: "rgba(32, 81, 177)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          maxWidth: 500
        }}
      >
        <h3 style={{ color: "white", lineHeight: 1.5 }}>
          We help the world's greatest brands use real-time visibility to create
          a more agile, efficient, and dynamic supply chain.
        </h3>
        <div
          style={{
            marginTop: 55,
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <StatBox
            headerText={"2,000,000"}
            bodyText={"Shipments Tracked Daily (Yes, 2 Million!)"}
          />
          <StatBox headerText={"620+"} bodyText={"Shipper, Brokers and 3PLs"} />
          <StatBox
            headerText={"18 of the top 20"}
            bodyText={"Food & beverage companies"}
          />
          <StatBox
            headerText={"9 of the top 10"}
            bodyText={"Consumer goods companies"}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerCarousel;
