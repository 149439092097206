import { FlexDiv } from "../../components/FlexDiv";
import SmallModal from "../../components/SmallModal";
import colors from "../../colors";
import { GoAlert } from "react-icons/go";

const DeleteModalTextField = ({ label, value }) => (
  <FlexDiv style={{ marginBottom: 15 }}>
    <div style={{ width: 90, color: colors.textGrey }}>{label}</div>
    <span>{value}</span>
  </FlexDiv>
);

export const DeleteModalContent = props => {
  const { appName, ipAddress, apiKey } = props;
  return (
    <div style={{ padding: "0 15px" }}>
      <FlexDiv style={{ alignItems: "center", marginTop: 15 }}>
        <GoAlert color={colors.watermelonRed} size={30} />
        <div
          style={{ fontSize: 14, marginLeft: 15, color: colors.watermelonRed }}
        >
          <div>Are you sure you want to delete this key record?</div>
          <div>This action cannot be undone!</div>
        </div>
      </FlexDiv>
      <div style={{ marginTop: 15 }}>
        <DeleteModalTextField label={"App Name:"} value={appName} />
        <DeleteModalTextField label={"IP4 Address:"} value={ipAddress} />
        <DeleteModalTextField label={"API Key:"} value={apiKey} />
      </div>
    </div>
  );
};

const DeleteModal = props => {
  const { applicationDetails, show, onHide, onConfirm } = props;
  const { appName, apiKey, ipAddress } = applicationDetails;
  return (
    <SmallModal
      show={show}
      title={"Delete API Key Record"}
      onHide={onHide}
      onConfirm={onConfirm}
    >
      <DeleteModalContent
        appName={appName}
        ipAddress={ipAddress}
        apiKey={apiKey}
        onCancel={null}
        onConfirm={null}
      />
    </SmallModal>
  );
};

export default DeleteModal;
