const SectionTitle = ({ text }) => {
  return (
    <div
      style={{
        borderBottom: "1px solid #CEE0F9",
        fontSize: 34,
        fontWeight: 300,
        paddingBottom: 13,
        letterSpacing: 0,
        width: "100%",
        color: "#21252A"
      }}
    >
      {text}
    </div>
  );
};

export default SectionTitle;
