import Colors from "../colors";

const Hyperlink = props => {
  const { text, onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        textDecoration: "underline",
        color: Colors.blue,
        cursor: "pointer"
      }}
    >
      {text}
    </div>
  );
};

export default Hyperlink;
