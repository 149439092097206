import colors from "../../colors";
import { FlexDiv } from "../../components/FlexDiv";
import SectionTitle from "./SectionTitle";
import WomandUpdate from "../../assets/woman-update.svg";

const sampleChangelog = [
  {
    versionNumber: "1.0.0",
    versionDate: "10-02-2020",
    versionDetails: [
      "Load visibility and Carrier Visibility bundles released",
      "Documentation update"
    ]
  }
];

const VersionItem = ({ item }) => {
  const { versionNumber, versionDate, versionDetails } = item;
  return (
    <div style={{ marginTop: 16 }}>
      <div style={{ color: colors.blue }}>
        Version {versionNumber} [{versionDate}]
      </div>
      <ul style={{ paddingLeft: 16, margin: 0 }}>
        {versionDetails.map((v, i) => (
          <li key={`${versionNumber}-${i}`} style={{ marginTop: 8 }}>
            {v}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ChangelogSection = () => {
  return (
    <FlexDiv
      style={{
        width: "100%",
        marginTop: 50,
        marginBottom: 142
      }}
    >
      <div
        style={{
          width: "50%",
          paddingTop: 50,
          paddingLeft: 180,
          boxSizing: "border-box"
        }}
      >
        <div style={{}}>
          <SectionTitle text="What's New" />
          {sampleChangelog.map(item => (
            <VersionItem item={item} key={item.versionNumber} />
          ))}
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <img
          src={WomandUpdate}
          alt="Woman Update"
          style={{ objectFit: "contain", width: "100%" }}
        />
      </div>
    </FlexDiv>
  );
};

export default ChangelogSection;
