import { useState } from "react";
import CustomerCarousel from "./CustomerCarousel";
import { LoginForm, RegistrationForm } from "./userForms";

const LoginPage = () => {
  const [showLoginForm, setShowLoginForm] = useState(true);
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {showLoginForm ? (
        <LoginForm changeFormHandler={() => setShowLoginForm(false)} />
      ) : (
        <RegistrationForm changeFormHandler={() => setShowLoginForm(true)} />
      )}
      <CustomerCarousel />
    </div>
  );
};

export default LoginPage;
