import { useState } from "react";
import { FiInfo } from "react-icons/fi";
import colors from "../colors";

const HoverIcon = props => {
  const { hoverStyle, style, hoverText, iconSize, icon } = props;
  const [showText, setShowText] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          ...style
        }}
        onMouseOver={() => setShowText(true)}
        onMouseOut={() => setShowText(false)}
      >
        {icon || <FiInfo size={iconSize} />}
      </div>
      {showText && (
        <div
          style={{
            zIndex: 99,
            position: "absolute",
            backgroundColor: colors.textBlack,
            opacity: 0.8,
            fontSize: 14,
            color: "white",
            display: "flex",
            justifyContent: "center",
            width: 300,
            padding: "5px 8px",
            boxSizing: "border-box",
            borderRadius: 4,
            ...hoverStyle
          }}
        >
          {hoverText}
        </div>
      )}
    </div>
  );
};

export default HoverIcon;
