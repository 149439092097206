import { useState, useEffect } from "react";
import { FiCopy, FiCheck, FiExternalLink } from "react-icons/fi";
import "./textInput.scss";
import colors from "../colors";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HoverIcon from "./HoverIcon";

const FieldLabel = ({ label, required, showError }) => (
  <div className={showError ? "field-label error" : "field-label"}>
    {label}
    {required && (
      <span className="field-label__required-label">(Required)</span>
    )}
  </div>
);

export const NavigationFieldLabel = ({ label, navPath }) => {
  const [showText, setShowText] = useState(false);
  const windowOpenHandler = () => {
    const win = window.open(navPath, "_blank");
    if (win) {
      win.focus();
    } else {
      alert("Please allow popups for this website");
    }
  };
  return (
    <div className="nav-field-label">
      {label}
      <div
        onClick={windowOpenHandler}
        className="nav-field-label__icon"
        onMouseOver={() => setShowText(true)}
        onMouseOut={() => setShowText(false)}
      >
        <FiExternalLink color={colors.blue} size={16} />
      </div>
      {showText && (
        <div className="nav-field-label__text">View Documentation</div>
      )}
    </div>
  );
};

export const TextInput = props => {
  const {
    placeholder,
    label,
    style,
    value,
    onChange,
    disabled,
    clickToCopy,
    setCopied,
    hoverIcon,
    type,
    required,
    validate,
    validateCheck,
    errorMessage,
    hoverText,
    hoverStyle
  } = props;
  const [focusedOnce, setFocusedOnce] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (focusedOnce) {
      setShowError(!validateCheck);
    }
  }, [validateCheck, focusedOnce]);

  const onFocusOutHandler = () => {
    setFocusedOnce(true);
    if (!validateCheck) {
      setShowError(true);
    }
  };

  const onChangeHandler = e => {
    onChange(e);
  };
  let errorClass = showError ? " text-input--error" : "";
  let disableClass = disabled ? " text-input--disable" : "";
  let clickToCopyClass = clickToCopy ? " text-input--click-to-copy" : "";

  return (
    <div className="text-input" style={style}>
      <FieldLabel label={label} required={required} showError={showError} />
      <div
        className={`text-input--input-container${errorClass}${disableClass}${clickToCopyClass}`}
      >
        <input
          autoComplete="off"
          onBlur={validate ? onFocusOutHandler : null}
          type={type ? type : "text"}
          name="text"
          className="text-input--input"
          placeholder={placeholder}
          value={value}
          onChange={e => onChangeHandler(e.target.value)}
          readOnly={disabled}
        />
        {clickToCopy && (
          <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
            {hoverText ? (
              <HoverIcon
                icon={<FiCopy />}
                hoverStyle={hoverStyle}
                hoverText={hoverText}
              />
            ) : (
              <FiCopy />
            )}
          </CopyToClipboard>
        )}
        {hoverIcon}
      </div>
      {showError ? (
        <span className="text-input__error-message">{errorMessage}</span>
      ) : (
        <div className="text-input__error-message--empty" />
      )}
    </div>
  );
};

export const StaticField = props => {
  const { label, style, children } = props;
  return (
    <div style={style}>
      <FieldLabel label={label} />
      {children}
    </div>
  );
};

export const ClickToCopyField = props => {
  const { value, label, style, hoverText, hoverStyle, showFieldOnHover } =
    props;
  const [copied, setCopied] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setTimeout(() => setCopied(false), 3000);
  }, [copied, setCopied]);

  return (
    <div className="click-to-copy-field">
      <div
        onMouseOver={showFieldOnHover ? () => setShowText(true) : null}
        onMouseOut={showFieldOnHover ? () => setShowText(false) : null}
        className="click-to-copy-field__hover-wrapper"
      />
      <TextInput
        value={value}
        label={label}
        disabled
        clickToCopy
        setCopied={setCopied}
        style={{ marginBottom: 0 }}
        hoverText={hoverText}
        hoverStyle={hoverStyle}
      />
      {showText && (
        <div className="click-to-copy-field__hover-value-text">{value}</div>
      )}
      <div
        className={
          copied
            ? "text-input__success-message"
            : "text-input__success-message--empty"
        }
        style={{
          ...style
        }}
      >
        <FiCheck className="text-input__check" size={12} />
        <span>Copied URL to clipboard</span>
      </div>
    </div>
  );
};

export const ValidatedTextInput = props => {
  const {
    label,
    placeholder,
    value,
    onChange,
    validateCheck,
    errorMessage,
    style,
    hoverIcon,
    required
  } = props;
  return (
    <div>
      <TextInput
        style={style}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        validateCheck={validateCheck}
        errorMessage={errorMessage}
        hoverIcon={hoverIcon}
        validate
        required={required}
      />
    </div>
  );
};

export const InfoField = ({ label, text, clickToCopy }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => setCopied(false), 3000);
  }, [copied, setCopied]);

  const copyElement = copied ? (
    <div className="info-field__copy-element">
      <FiCheck className="info-field__check" />
      <span>Copied</span>
    </div>
  ) : (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <FiCopy className="info-field__copy" />
    </CopyToClipboard>
  );

  return (
    <StaticField
      label={label}
      style={{
        marginTop: 24,
        color: colors.mediumGrey
      }}
    >
      <div className="info-field">
        <span className="info-field__text">{text}</span>
        {clickToCopy && copyElement}
      </div>
    </StaticField>
  );
};

export default TextInput;
