import Toggle from "react-toggle";
import "./buttons.scss";

export const ConfirmButton = props => {
  const { label, onClick, style, inverted, disable } = props;
  let className = "confirm-btn";
  if (inverted) {
    className += " inverted";
  }

  if (disable) {
    className += " disable";
  }

  return (
    <button
      onClick={disable ? null : onClick}
      type="submit"
      disabled={disable}
      className={className}
      style={style}
    >
      {label}
    </button>
  );
};

export const SlideToggle = props => {
  const { active, onChange } = props;
  const label = active ? "Active" : "Inactive";
  return (
    <div className="slide-toggle">
      <Toggle defaultChecked={active} onChange={onChange} icons={false} />
      <span className="slide-toggle__label">{label}</span>
    </div>
  );
};
