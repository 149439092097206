import colors from "../../colors";
import WelcomeSection from "./WelcomeSection";
import ChangelogSection from "./ChangelogSection";

const IntroductionPage = () => {
  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: colors.backgroundBlue
      }}
    >
      <WelcomeSection />
      <ChangelogSection />
    </div>
  );
};

export default IntroductionPage;
