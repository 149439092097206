import { useEffect, useRef, useState } from "react";
import { BsFillXCircleFill } from "react-icons/bs";
import {
  AsyncTypeahead,
  Hint,
  Menu,
  MenuItem
} from "react-bootstrap-typeahead";
import "./companySelector.scss";

const CompanySelector = props => {
  const {
    onSearch,
    isLoading,
    options,
    onSubmit,
    defaultSelected,
    clearCompany
  } = props;
  const [selected, setSelected] = useState([]);
  const [showMenu, setShowMenu] = useState(true);
  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    }
    // eslint-disable-next-line
  }, []);

  const onBlurHandler = () => {
    if (selected.length === 0) {
      setShowMenu(false);
      setSelected(defaultSelected);
      setTimeout(() => {
        setShowMenu(true);
      }, 500);
    }
  };

  const ref = useRef();
  return (
    <div className="company-selector">
      <AsyncTypeahead
        defaultSelected={defaultSelected}
        ref={ref}
        id="async-company-selector"
        isLoading={isLoading}
        labelKey={option => `${option.description}`}
        placeholder="Choose a company..."
        onSearch={onSearch}
        options={options}
        selected={selected}
        onBlur={onBlurHandler}
        onChange={selected => {
          setSelected(selected);
          if (selected.length > 0) {
            onSubmit(selected[0]);
          }
        }}
        renderInput={props => {
          const { inputRef, referenceElementRef, ...inputProps } = props;
          const newInputProps = {
            ...inputProps,
            className: "typeahead-input"
          };
          return (
            <Hint
              shouldSelect={(shouldSelect, e) => {
                return e.code === "Enter" || e.code === "Tab" || shouldSelect;
              }}
            >
              <div style={{ display: "flex" }}>
                <input
                  {...newInputProps}
                  ref={input => {
                    inputRef(input);
                    referenceElementRef(input);
                  }}
                />
                <div
                  className={
                    selected.length > 0
                      ? "company-selector__clear-btn"
                      : "company-selector__clear-btn disabled"
                  }
                  onClick={() => {
                    if (selected.length) {
                      ref.current.clear();
                      setSelected([]);
                      ref.current.focus();
                      clearCompany();
                    }
                  }}
                >
                  {selected.length > 0 && (
                    <BsFillXCircleFill size={20} color={"#636363"} />
                  )}
                </div>
              </div>
            </Hint>
          );
        }}
        renderMenu={(results, menuProps) => {
          if (!showMenu) {
            return null;
          }
          return (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem option={result} position={index} key={result.id}>
                  {result.description}
                </MenuItem>
              ))}
            </Menu>
          );
        }}
      />
    </div>
  );
};

export default CompanySelector;
