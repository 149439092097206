import { Drawer } from "rsuite";
import colors from "../colors";
import { ConfirmButton } from "./Buttons";
import { FlexDiv } from "./FlexDiv";
import { FiX, FiSave, FiEdit } from "react-icons/fi";
import "./sideDrawer.css";

export const FooterTypes = {
  SAVE: "footer_save",
  CLOSE_ONLY: "footer_close_only",
  EDIT: "footer_edit"
};

const SideDrawer = props => {
  const {
    show,
    setShow,
    title,
    children,
    hideFooter,
    onSave,
    disableSave,
    footerType = FooterTypes.CLOSE_ONLY,
    onEdit
  } = props;
  let footer = null;
  if (!hideFooter) {
    switch (footerType) {
      case FooterTypes.CLOSE_ONLY:
        footer = (
          <>
            <ConfirmButton
              onClick={() => setShow(false)}
              label="Close"
              style={{
                flexGrow: 1,
                marginRight: 5,
                marginLeft: 20,
                height: 32,
                color: "white",
                fontSize: 14
              }}
            />
          </>
        );
        break;
      case FooterTypes.SAVE:
        footer = (
          <>
            <ConfirmButton
              onClick={() => setShow(false)}
              label={
                <FlexDiv style={{ alignItems: "center" }}>
                  <FiX size={17} style={{ marginRight: 5 }} />
                  <span>Cancel</span>
                </FlexDiv>
              }
              style={{
                flexGrow: 1,
                marginRight: 5,
                marginLeft: 20,
                height: 32,
                backgroundColor: colors.cancelGrey,
                color: "black",
                fontSize: 14,
                border: `1px solid ${colors.cancelGrey}`
              }}
            />
            <ConfirmButton
              onClick={() => {
                onSave();
                setShow(false);
              }}
              disable={disableSave}
              label={
                <FlexDiv style={{ alignItems: "center" }}>
                  <FiSave size={17} style={{ marginRight: 5 }} />
                  <span>Save</span>
                </FlexDiv>
              }
              style={{
                flexGrow: 1,
                marginRight: 10,
                marginLeft: 5,
                height: 32,
                fontSize: 14
              }}
            />
          </>
        );
        break;
      case FooterTypes.EDIT:
        footer = (
          <>
            <ConfirmButton
              onClick={onEdit}
              label={
                <FlexDiv style={{ alignItems: "center" }}>
                  <FiEdit size={17} style={{ marginRight: 5 }} />
                  <span>Edit Record</span>
                </FlexDiv>
              }
              style={{
                flexGrow: 1,
                marginRight: 5,
                marginLeft: 20,
                height: 32,
                color: "white",
                fontSize: 14
              }}
            />
          </>
        );
        break;
      default:
        footer = null;
    }
  }

  return (
    <Drawer
      classPrefix={"custom-drawer"}
      show={show}
      onHide={() => setShow(false)}
      backdrop={true}
      size="xs"
    >
      <Drawer.Header classPrefix={"custom-drawer-header"}>
        {title}
      </Drawer.Header>
      <Drawer.Body classPrefix={"custom-drawer-body"}>{children}</Drawer.Body>
      <Drawer.Footer classPrefix={"custom-drawer-footer"}>
        {footer}
      </Drawer.Footer>
    </Drawer>
  );
};

export default SideDrawer;
