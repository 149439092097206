import Lottie from "react-lottie";
import spinnerData from "../assets/blue-loading-spinner.json";

const Spinner = () => {
  const defaultOptions = {
    loopt: true,
    autoplay: true,
    animationData: spinnerData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
};

export default Spinner;
