import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import ApiKeyManagementPage from "./modules/api-key-management/ApiKeyManagementPage";
import AppNavBar, { PageKeys } from "./modules/app-nav/AppNavBar";
import IntroductionPage from "./modules/introduction/IntroductionPage";
import LoginPage from "./modules/login/LoginPage";
import { FlexDiv } from "./components/FlexDiv";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersState from "./modules/users/UsersState";
import Spinner from "./components/Spinner";

const Page = props => {
  return (
    <FlexDiv style={{ flexDirection: "column", minHeight: "100vh" }}>
      {props.children}
    </FlexDiv>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [cookies] = useCookies(["auth-token"]);
  const authed = !!cookies["auth-token"];
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? <Component {...props} /> : <Redirect exact to="/" />
      }
    />
  );
};

function App() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["auth-token", "user-id", "device-id"]);

  const cookiesExist =
    cookies["auth-token"] && cookies["user-id"] && cookies["device-id"];

  const loading = useSelector(UsersState.selectors.getLoadingStatus);
  // if cookies exist, validate credentials
  useEffect(() => {
    if (!cookiesExist) {
      dispatch(UsersState.actionCreators.navigateToLogin());
    } else {
      dispatch(UsersState.actionCreators.validateCookies());
    }
    // eslint-disable-next-line
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (cookiesExist && location.pathname !== "/") {
      dispatch(UsersState.actionCreators.validateCookies());
    }
    // eslint-disable-next-line
  }, [location]);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Switch>
        <PrivateRoute
          key={location.key}
          exact
          path={PageKeys.INTRODUCTION.path}
          component={() => (
            <Page>
              <AppNavBar selectedPage={PageKeys.INTRODUCTION.key} />
              <IntroductionPage />
            </Page>
          )}
        />
        <PrivateRoute
          key={location.key}
          exact
          path={PageKeys.KEY_MANAGEMENT.path}
          component={() => (
            <Page>
              <AppNavBar selectedPage={PageKeys.KEY_MANAGEMENT.key} />
              <ApiKeyManagementPage />
            </Page>
          )}
        />
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route
          render={() => (
            <Redirect to={{ pathname: PageKeys.INTRODUCTION.path }} />
          )}
        />
      </Switch>
    </>
  );
}

export default App;
