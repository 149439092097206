/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Cookies from "universal-cookie";
import { push } from "connected-react-router";
import ApiKeyManagementState from "../api-key-management/ApiKeyManagementState";

const STORE_MOUNT_POINT = "users";

const baseUrl = `https://${window.devPortalConfig.USER_SERVICE_HOST}/api/v1`;
const companyUrl = `https://${window.devPortalConfig.COMPANY_SERVICE_HOST}/api/v1`;

// ACTIONS
const LOADING = "Users/LOADING";
const SUCCESSFUL_LOGIN = "Users/SUCCESSFUL_LOGIN";
const FAILED_LOGIN = "Users/FAILED_LOGIN";
const SUCCESSFUL_LOGOUT = "Users/SUCCESSFUL_LOGOUT";
const FAILED_LOGOUT = "Users/FAILED_LOGOUT";
const VALIDATE_KEY_SUCCESS = "Users/VALIDATE_KEY_SUCCESS";
const VALIDATE_KEY_FAILURE = "Users/VALIDATE_KEY_FAILURE";
const SET_SELECTED_COMPANY = "Users/SET_SELECTED_COMPANY";
const CLEAR_SELECTED_COMPANY = "Users/CLEAR_SELECTED_COMPANY";
const FETCH_COMPANIES = "Users/FETCH_COMPANIES";
const START_AUTOCOMPLETE_LOADING = "Users/START_AUTOCOMPLETE_LOADING";
const END_AUTOCOMPLETE_LOADING = "Users/END_AUTOCOMPLETE_LOADING";

// COOKIES
export const cookies = new Cookies();

const saveCookie = (authToken, userId, deviceId) => {
  cookies.set("auth-token", authToken, { path: "/", sameSite: "lax" });
  cookies.set("user-id", userId, { path: "/", sameSite: "lax" });
  cookies.set("device-id", deviceId, { path: "/", sameSite: "lax" });
};

const saveCompanyCookies = company => {
  const { id, description } = company;
  cookies.set("company-name", description, { path: "/", sameSite: "lax" });
  cookies.set("company-id", id, { path: "/", sameSite: "lax" });
};

const clearCompanyCookies = () => {
  cookies.remove("company-id", { path: "/", sameSite: "lax" });
  cookies.remove("company-name", { path: "/", sameSite: "lax" });
};

export const clearCookies = () => {
  cookies.remove("auth-token", { path: "/", sameSite: "lax" });
  cookies.remove("user-id", { path: "/", sameSite: "lax" });
  cookies.remove("device-id", { path: "/", sameSite: "lax" });
  cookies.remove("company-id", { path: "/", sameSite: "lax" });
  cookies.remove("company-name", { path: "/", sameSite: "lax" });
};

export const getCookies = () => {
  const authToken = cookies.get("auth-token");
  const userId = cookies.get("user-id");
  const deviceId = cookies.get("device-id");
  const companyId = cookies.get("company-id");
  const companyName = cookies.get("company-name");
  return {
    authToken,
    userId,
    deviceId,
    companyId,
    companyName
  };
};

const validateCompany = company => {
  return company.status !== "rejected" && company.active;
};

// ACTION CREATORS
const userLogin = (username, password) => {
  const loginUrl = `${baseUrl}/users/login`;
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  return async (dispatch, getState) => {
    try {
      const userResponse = await axios.post(loginUrl, formData);
      const { authToken, user, deviceId } = userResponse.data;
      const companyServiceUrl = `${companyUrl}/companies/${user.company.id}`;
      const isSuperAdmin = user.superAdmin;
      let company = null;
      if (!isSuperAdmin) {
        const companyResponse = await axios.get(companyServiceUrl, {
          auth: {
            username,
            password
          }
        });
        company = companyResponse.data.company;
      }
      // a valid user role is admin
      const validUserRole = user.role === "admin" || isSuperAdmin;

      // user's company must be active (active: true) and not rejected (status !== 'rejected')
      const validCompany = user.superAdmin ? true : validateCompany(company);

      if (validUserRole && validCompany) {
        dispatch({
          type: SUCCESSFUL_LOGIN,
          payload: userResponse.data.user,
          isSuperAdmin: user.superAdmin,
          companyId: isSuperAdmin ? null : user.company.id
        });
        saveCookie(authToken, user.userId, deviceId);
        dispatch(push("/introduction"));
      } else {
        dispatch({
          type: FAILED_LOGIN,
          payload: "Invalid Permissions"
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: FAILED_LOGIN,
        payload: "Invalid Email or Password"
      });
    }
  };
};

const fetchCompanies = query => {
  const { authToken, userId, deviceId } = getCookies();
  const companyServiceUrl = `${companyUrl}/companies/autocomplete?q=${query}`;
  return async dispatch => {
    dispatch({ type: START_AUTOCOMPLETE_LOADING });
    try {
      const companyResponse = await axios.get(companyServiceUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-FourKitesUserId": userId,
          "X-FourKitesDeviceId": deviceId
        }
      });
      dispatch({
        type: FETCH_COMPANIES,
        payload: companyResponse.data
      });
    } catch (err) {
      dispatch({ type: END_AUTOCOMPLETE_LOADING });
      console.error(err);
    }
  };
};

const failedValidation = (emptyCookies = true) => {
  return dispatch => {
    if (emptyCookies) {
      clearCookies();
    }
    dispatch(push("/"));
    dispatch({
      type: VALIDATE_KEY_FAILURE
    });
  };
};

const validateSuperAdmin = userResponse => {
  const { authToken, userId, deviceId, companyId } = getCookies();
  return async dispatch => {
    // scenarios:
    // 1. no company id stored in cookies
    // 2. company id stored in cookies -> invalid company
    // 3. company id stored in cookies -> valid company
    try {
      if (!companyId) {
        // scenario 1: if no company id in cookies, navigate to intro page
        dispatch({
          type: VALIDATE_KEY_SUCCESS,
          payload: userResponse.data.user,
          isSuperAdmin: true,
          companyId: null
        });
      } else if (companyId) {
        // check company validation if company id exists in cookies
        const companyServiceUrl = `${companyUrl}/companies/${companyId}`;
        const companyResponse = await axios.get(companyServiceUrl, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-FourKitesUserId": userId,
            "X-FourKitesDeviceId": deviceId
          }
        });
        const { company } = companyResponse.data;
        const validCompany = validateCompany(company);
        if (!validCompany) {
          // scenario 2: company is not valid
          dispatch({
            type: VALIDATE_KEY_SUCCESS,
            payload: userResponse.data.user,
            isSuperAdmin: true,
            companyId: null
          });
        } else {
          // scenario 3: company is valid
          dispatch({
            type: VALIDATE_KEY_SUCCESS,
            payload: userResponse.data.user,
            isSuperAdmin: true,
            companyId: company.id,
            company: { id: company.id, description: company.name }
          });
        }
      }
    } catch (err) {
      dispatch(failedValidation());
    }
  };
};

const validateNonSuperAdmin = userResponse => {
  const { authToken, userId, deviceId } = getCookies();
  return async (dispatch, getState) => {
    try {
      const intendedPath = getState().router.location.pathname;
      const companyId = userResponse.data.user.companyId;
      const companyServiceUrl = `${companyUrl}/companies/${companyId}`;
      const companyResponse = await axios.get(companyServiceUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-FourKitesUserId": userId,
          "X-FourKitesDeviceId": deviceId
        }
      });
      const { company } = companyResponse.data;
      const validCompany = validateCompany(company);
      if (validCompany) {
        dispatch({
          type: VALIDATE_KEY_SUCCESS,
          payload: userResponse.data.user,
          isSuperAdmin: false,
          companyId: company.id
        });
        if (intendedPath === "/") {
          dispatch(push("/introduction"));
        }
      } else {
        dispatch(failedValidation());
      }
    } catch (err) {
      dispatch(failedValidation());
    }
  };
};

const validateCookies = () => {
  const url = `${baseUrl}/users/validate_auth_token`;
  const { authToken, userId, deviceId } = getCookies();
  return async dispatch => {
    try {
      const userResponse = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-FourKitesUserId": userId,
            "X-FourKitesDeviceId": deviceId
          }
        }
      );
      const { user } = userResponse.data;
      const isSuperAdmin = user.superAdmin;
      const validUserRole = user.role === "admin" || isSuperAdmin;
      if (validUserRole) {
        if (isSuperAdmin) {
          dispatch(validateSuperAdmin(userResponse));
        } else {
          dispatch(validateNonSuperAdmin(userResponse));
        }
      } else {
        dispatch(failedValidation(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(failedValidation());
    }
  };
};

const userLogout = () => {
  const url = `${baseUrl}/users/logout`;
  const { authToken, userId, deviceId } = getCookies();
  return async dispatch => {
    try {
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-FourKitesUserId": userId,
          "X-FourKitesDeviceId": deviceId
        }
      });
      dispatch(push("/"));
      clearCookies();
      dispatch({
        type: SUCCESSFUL_LOGOUT
      });
    } catch (err) {
      console.error(err);
      dispatch(push("/"));
      clearCookies();
      dispatch({ type: FAILED_LOGOUT, payload: err.error });
    }
  };
};

const navigateToLogin = () => {
  return async dispatch => {
    dispatch(push("/"));
    clearCookies();
    dispatch({ type: SUCCESSFUL_LOGOUT });
  };
};

const setSelectedCompany = selectedCompany => {
  return async dispatch => {
    dispatch({ type: SET_SELECTED_COMPANY, payload: selectedCompany });
    const { id } = selectedCompany;
    saveCompanyCookies(selectedCompany);
    if (!id) {
      dispatch(push("/introduction"));
    } else {
      dispatch(ApiKeyManagementState.actionCreators.fetchApiBundles());
      dispatch(ApiKeyManagementState.actionCreators.fetchApplicationList());
    }
  };
};

const clearSelectedCompany = () => {
  return dispatch => {
    clearCompanyCookies();
    dispatch({ type: CLEAR_SELECTED_COMPANY });
    dispatch(push("/introduction"));
  };
};

const getArchbeeJwt = () => {
  const url = `${baseUrl}/users/jwt_for_archbee`;
  const { authToken, userId, deviceId } = getCookies();
  return async dispatch => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-FourKitesUserId": userId,
          "X-FourKitesDeviceId": deviceId
        }
      });
      return response.data.jwt;
    } catch (err) {
      console.log(err);
    }
  };
};

const initialState = {
  currentUser: null,
  companyId: null,
  company: {},
  error: null,
  loading: true,
  isSuperAdmin: null,
  companies: [],
  autocompleteLoading: false
};

// REDUCER
const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_AUTOCOMPLETE_LOADING:
      return {
        ...state,
        autocompleteLoading: true
      };
    case END_AUTOCOMPLETE_LOADING:
      return {
        ...state,
        autocompleteLoading: false
      };
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case VALIDATE_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        isSuperAdmin: action.isSuperAdmin,
        companyId: action.companyId,
        company: action.company || {}
      };
    case VALIDATE_KEY_FAILURE:
      return {
        ...state,
        loading: false
      };
    case SUCCESSFUL_LOGIN:
      return {
        ...state,
        error: null,
        loading: false,
        currentUser: action.payload,
        isSuperAdmin: action.isSuperAdmin,
        companyId: action.companyId
      };
    case FAILED_LOGIN:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SUCCESSFUL_LOGOUT:
      return {
        ...initialState,
        loading: false
      };
    case FAILED_LOGOUT:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        company: action.payload,
        companyId: action.payload.id
      };
    case CLEAR_SELECTED_COMPANY:
      return {
        ...state,
        company: {},
        companyId: null
      };
    case FETCH_COMPANIES:
      return {
        ...state,
        autocompleteLoading: false,
        companies: action.payload
      };
    default:
      return state;
  }
};

// SELECTORS
const getCurrentUser = state => state[STORE_MOUNT_POINT].currentUser;
const getAuthError = state => state[STORE_MOUNT_POINT].error;
const getLoadingStatus = state => state[STORE_MOUNT_POINT].loading;
const getSelectedCompany = state => state[STORE_MOUNT_POINT].company;
const getSelectedCompanyId = state => state[STORE_MOUNT_POINT].companyId;
const getIsSuperAdmin = state => state[STORE_MOUNT_POINT].isSuperAdmin;
const getCompanies = state => state[STORE_MOUNT_POINT].companies;
const getAutocompleteLoading = state =>
  state[STORE_MOUNT_POINT].autocompleteLoading;

export default {
  mountPoint: STORE_MOUNT_POINT,
  actions: {
    SUCCESSFUL_LOGIN,
    SUCCESSFUL_LOGOUT
  },
  actionCreators: {
    userLogin,
    userLogout,
    validateCookies,
    navigateToLogin,
    setSelectedCompany,
    fetchCompanies,
    clearSelectedCompany
  },
  helpers: {
    getArchbeeJwt
  },
  selectors: {
    getCurrentUser,
    getAuthError,
    getLoadingStatus,
    getSelectedCompany,
    getIsSuperAdmin,
    getCompanies,
    getAutocompleteLoading,
    getSelectedCompanyId
  },
  reducer: UsersReducer
};
