import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import ApiKeyManagementState from "./modules/api-key-management/ApiKeyManagementState";
import UsersState from "./modules/users/UsersState";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    [ApiKeyManagementState.mountPoint]: ApiKeyManagementState.reducer,
    [UsersState.mountPoint]: UsersState.reducer
  });

export const history = createBrowserHistory({ basename: "developerportal" });

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(applyMiddleware(thunk, routerMiddleware(history)))
  );

  return store;
}
