import { useEffect } from "react";
import {
  ClickToCopyField,
  ValidatedTextInput
} from "../../components/TextInput";
import ApiBundles from "./ApiBundles";
import { useDispatch, useSelector } from "react-redux";
import ApiKeyManagementState from "./ApiKeyManagementState";
import randomstring from "randomstring";
import HoverIcon from "../../components/HoverIcon";

export const ipAddressInfo =
  "Please provide the static IP address from which requests will be made";

export const validateIpAddress = ipAddress => {
  if (ipAddress === "") {
    return true;
  }
  const validIp4 =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipAddress
    );
  const validIp6 =
    /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/.test(
      ipAddress
    );
  return validIp4 || validIp6;
};

const NewApiKeyForm = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    const apiKey = randomstring.generate({
      length: 29,
      charset: "alphanumeric",
      capitalization: "uppercase"
    });
    dispatch(
      ApiKeyManagementState.actionCreators.updateFormData({
        apiKey,
        appName: "",
        ipAddress: "",
        active: true,
        apiBundles: [],
        companyId: "sankarbabudevcarrier"
      })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  const appData = useSelector(ApiKeyManagementState.selectors.getFormData);

  const updateFormData = (key, value) => {
    dispatch(
      ApiKeyManagementState.actionCreators.updateFormData({
        ...appData,
        [key]: value
      })
    );
  };

  useEffect(() => {
    // check form to enable or disable save
    // 1. appName must be populated
    // 2. at least one bundle must be selected
    let allowSave = false;
    if (
      appData.appName.length >= 6 &&
      appData.apiBundles.length > 0 &&
      validateIpAddress(appData.ipAddress)
    ) {
      allowSave = true;
    }
    dispatch(ApiKeyManagementState.actionCreators.enableSave(allowSave));
  }, [dispatch, appData]);

  return (
    <div>
      <ValidatedTextInput
        label={"App Name"}
        placeholder="New Application Name"
        value={appData.appName}
        onChange={appName => updateFormData("appName", appName)}
        style={{ marginBottom: 18, fontSize: 14 }}
        required
        validateCheck={appData?.appName?.length >= 6}
        errorMessage={"App name should have a minimum of 6 characters"}
      />
      <ValidatedTextInput
        label={"IP Address"}
        placeholder="1.1.1.1 (or IPv6)"
        value={appData.ipAddress}
        onChange={ipAddress => updateFormData("ipAddress", ipAddress)}
        style={{ marginBottom: 28, fontSize: 14 }}
        validateCheck={validateIpAddress(appData?.ipAddress)}
        errorMessage={"Enter a valid IPv4 or IPv6 address"}
        hoverIcon={
          <HoverIcon
            hoverText={ipAddressInfo}
            hoverStyle={{
              bottom: 30,
              right: 0
            }}
          />
        }
      />
      <ClickToCopyField
        label={"API Key"}
        value={appData.apiKey}
        style={{ marginBottom: 24, fontSize: 14 }}
        hoverText={"Click to copy API key"}
        hoverStyle={{ right: 0, width: 150, top: -42 }}
      />
      <ApiBundles
        apiBundles={appData.apiBundles || []}
        updateSelectedBundles={newSelectedBundleIds =>
          updateFormData("apiBundles", newSelectedBundleIds)
        }
      />
    </div>
  );
};

export default NewApiKeyForm;
