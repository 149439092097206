import React, { useState, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { FiEdit, FiTrash2, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { FlexDiv } from "../../components/FlexDiv";
import { SlideToggle } from "../../components/Buttons";
import { formatTs } from "./EditApplicationRecordForm";
import Colors from "../../colors";
import "./applicationTable.css";
import Hyperlink from "../../components/Hyperlink";
import { useDispatch } from "react-redux";
import ApiKeyManagementState from "./ApiKeyManagementState";
import { FiCopy, FiCheck } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EDIT_APPLICATION, APPLICATION_DETAILS } from "./ApiKeyManagementPage";

import colors from "../../colors";

const ApplicationTable = props => {
  const { data, openModal, setApplication, openDeleteModal } = props;
  const dispatch = useDispatch();
  const updateStatusHandler = (apiKey, status) => {
    dispatch(
      ApiKeyManagementState.actionCreators.updateApplication(apiKey, {
        active: status
      })
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: (original, rowIdx) => {
          return rowIdx + 1;
        }
      },
      {
        Header: "App Name",
        accessor: "appName"
      },
      {
        Header: "API Key",
        accessor: "apiKey",
        Cell: props => {
          const defaultCopiedMessage = (
            <div
              style={{
                marginLeft: 14,
                color: colors.blue,
                display: "flex",
                alignItems: "center"
              }}
            >
              <FiCopy color={colors.blue} />
              <span style={{ marginLeft: 5, fontSize: 12 }}>Click to Copy</span>
            </div>
          );

          const successfullyCopiedMessage = (
            <div
              style={{
                marginLeft: 14,
                color: colors.successGreen,
                display: "flex",
                alignItems: "center"
              }}
            >
              <FiCheck color={colors.successGreen} />
              <span style={{ marginLeft: 5, fontSize: 12 }}>Copied!</span>
            </div>
          );
          const [showCopy, setShowCopy] = useState(false);
          const [copied, setCopied] = useState(false);
          const [copyMessage, setCopyMessage] = useState(defaultCopiedMessage);
          const { value } = props;
          const truncatedApiKey = `${value.slice(0, 4)}-XXXX`;
          useEffect(() => {
            if (copied) {
              setCopyMessage(successfullyCopiedMessage);
            } else {
              setCopyMessage(defaultCopiedMessage);
            }
            // eslint-disable-next-line
          }, [copied]);

          useEffect(() => {
            if (!showCopy) {
              setCopied(false);
            }
          }, [showCopy]);

          return (
            <div
              onClick={e => {
                e.stopPropagation();
              }}
              onMouseEnter={() => setShowCopy(true)}
              onMouseLeave={() => setShowCopy(false)}
              style={{
                display: "flex",
                alignItems: "center",
                height: 62,
                width: 200,
                padding: "0px 12px"
              }}
            >
              {truncatedApiKey}
              {showCopy && (
                <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                  {copyMessage}
                </CopyToClipboard>
              )}
            </div>
          );
        }
      },
      {
        Header: "Bundles Used",
        accessor: "apiBundles",
        Cell: ({ value, row }) => {
          const bundles = value;
          const firstBundle = bundles.length > 0 ? bundles[0] : null;
          const extraBundles =
            bundles.length > 1 ? `+${bundles.length - 1} more` : null;
          return (
            <div style={{ width: 200 }}>
              {!!firstBundle && firstBundle.name}{" "}
              {extraBundles && (
                <Hyperlink
                  text={extraBundles}
                  onClick={() => {
                    setApplication(row.original);
                    openModal(APPLICATION_DETAILS);
                  }}
                />
              )}
            </div>
          );
        }
      },
      {
        Header: "Key Status",
        accessor: "active",
        Cell: props => {
          const { value, row } = props;
          const { apiKey } = row.original;
          return (
            <div onClick={e => e.stopPropagation()} style={{ width: 100 }}>
              <SlideToggle
                active={value}
                onChange={() => {
                  updateStatusHandler(apiKey, !value);
                }}
              />
            </div>
          );
        }
      },
      {
        Header: "IP Address",
        accessor: "ipAddress",
        Cell: ({ value }) => <div style={{ width: 330 }}>{value}</div>
      },
      {
        Header: "Created On",
        accessor: "createdAt",
        Cell: ({ value }) => <div style={{ width: 200 }}>{formatTs(value)}</div>
      },
      {
        Header: "Last Modified On",
        accessor: "updatedAt",
        Cell: ({ value }) => <div style={{ width: 200 }}>{formatTs(value)}</div>
      },
      {
        Header: "Last Modified By",
        accessor: "updatedBy"
      },
      {
        Header: "Actions",
        accessor: (original, rowIdx) => {
          return (
            <FlexDiv
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <div
                onClick={e => {
                  e.stopPropagation();
                  const { appName, ipAddress, active, apiBundles } = original;
                  setApplication(original);
                  dispatch(
                    ApiKeyManagementState.actionCreators.updateFormData({
                      appName,
                      ipAddress,
                      active,
                      apiBundles: apiBundles.map(b => b.id)
                    })
                  );
                  openModal(EDIT_APPLICATION);
                }}
              >
                <FiEdit color={Colors.blue} size={18} />
              </div>
              <div
                onClick={e => {
                  e.stopPropagation();
                  setApplication(original);
                  openDeleteModal();
                }}
              >
                <FiTrash2
                  color={Colors.red}
                  size={18}
                  style={{ marginLeft: 10 }}
                />
              </div>
            </FlexDiv>
          );
        }
      }
    ],
    // eslint-disable-next-line
    [openModal, setApplication, openDeleteModal]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  const numApplications = data.length;
  return (
    <div
      style={{
        borderRadius: 3,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
        backgroundColor: "white",
        overflowX: "scroll"
      }}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span style={{ position: "relative", top: 2 }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FiChevronDown size={16} />
                      ) : (
                        <FiChevronUp size={16} />
                      )
                    ) : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            return (
              <tr
                {...row.getRowProps()}
                // onClick={() => console.log(row)}
                onClick={() => {
                  setApplication(row.original);
                  openModal(APPLICATION_DETAILS);
                }}
              >
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className:
                            cell.column.id === "apiKey" ? "apiKey" : null
                        }
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <FlexDiv
        style={{
          paddingLeft: 20,
          height: 50,
          alignItems: "center"
        }}
      >
        {numApplications > 0 && (
          <span>
            Showing 1-{numApplications} of {numApplications} entries
          </span>
        )}
      </FlexDiv>
    </div>
  );
};

export default ApplicationTable;
