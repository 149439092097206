import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ApplicationTable from "./ApplicationTable";
import BundleTile from "./BundleTile";
import { ConfirmButton } from "../../components/Buttons";
import { FlexDiv } from "../../components/FlexDiv";
import ApiKeyManagementState from "./ApiKeyManagementState";
import { useEffect } from "react";
import { FiFileText, FiPlus, FiSlash } from "react-icons/fi";
import SideDrawer, { FooterTypes } from "../../components/SideDrawer";
import NewApiKeyForm from "./NewApiKeyForm";
import EditApplicationRecordForm from "./EditApplicationRecordForm";
import ApplicationDetailsForm from "./ApplicationDetailsForm";
import BundleDetailsForm from "./BundleDetailsForm";
import DeleteModal from "./DeleteModal";
import ClipLoader from "react-spinners/ClipLoader";
import colors from "../../colors";
import UsersState from "../users/UsersState";
import { ToastContainer, toast } from "react-toastify";
import NoBundles from "../../assets/no-subscribed-bundles.png";
import "react-toastify/dist/ReactToastify.css";
import "./apiKeyManagementPage.scss";

const {
  getApplicationList,
  getApiBundles,
  getLoadingStatus,
  getMessage,
  getEnableSaveStatus
} = ApiKeyManagementState.selectors;
const { fetchApplicationList, fetchApiBundles, clearMessage } =
  ApiKeyManagementState.actionCreators;

const NEW_API_KEY = "new_api_form";
export const EDIT_APPLICATION = "edit_application_form";
const BUNDLE_DETAILS = "bundle_details_form";
export const APPLICATION_DETAILS = "application_details_form";
const API_KEY_LIMIT = 2;

const modalTitles = {
  [NEW_API_KEY]: "Generate A New API Key",
  [EDIT_APPLICATION]: "Edit Application Record",
  [BUNDLE_DETAILS]: "Bundle Details",
  [APPLICATION_DETAILS]: "View Application Record"
};

const footerTypeMapping = {
  [NEW_API_KEY]: FooterTypes.SAVE,
  [EDIT_APPLICATION]: FooterTypes.SAVE,
  [BUNDLE_DETAILS]: FooterTypes.CLOSE_ONLY,
  [APPLICATION_DETAILS]: FooterTypes.EDIT
};

const GenerateNewKeyButton = ({ onClick }) => (
  <ConfirmButton
    label={
      <FlexDiv style={{ alignItems: "center" }}>
        <FiPlus size={15} style={{ marginRight: 10 }} />
        <span>Generate New API Key</span>
      </FlexDiv>
    }
    style={{ fontSize: 14, height: 32 }}
    onClick={onClick}
  />
);

const ViewDocumentationButton = () => {
  const dispatch = useDispatch();
  const windowOpenHandler = async () => {
    try {
      const archbeeUrl = `https://docs.fourkites.com/api-reference`;
      const archbeeJwt = await dispatch(UsersState.helpers.getArchbeeJwt());
      const jwtAddedUrl = `${archbeeUrl}?jwt=${archbeeJwt}`;
      const win = window.open(jwtAddedUrl, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Please allow popus for this website");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ConfirmButton
      onClick={windowOpenHandler}
      style={{ height: 32, fontSize: 14 }}
      label={
        <FlexDiv style={{ alignItems: "center" }}>
          <FiFileText size={14} style={{ marginRight: 10 }} />
          <span>View API Documentation</span>
        </FlexDiv>
      }
      inverted
    />
  );
};

const ApiBundle = props => {
  const {
    loading,
    bundles,
    openModal,
    setModalKey,
    setBundle,
    selectedBundle
  } = props;
  const activeBundles = bundles.filter(b => b.activeItems.length > 0);
  return (
    <div className="api-key-management-page__bundle-section">
      <div className="api-key-management-page__bundle-section-title">
        <div className="api-key-management-page__section-title">
          {activeBundles.length > 0
            ? `Subscribed Api Bundles (${activeBundles.length})`
            : ""}
        </div>
        <ViewDocumentationButton />
      </div>
      {activeBundles.length > 0 ? (
        <div className="api-key-management-page__bundle-section-bundle-container">
          {activeBundles.map(b => (
            <BundleTile
              key={b.name}
              selected={selectedBundle && selectedBundle.id === b.id}
              bundle={b}
              style={{ marginRight: 20, marginBottom: 20 }}
              onClick={() => {
                setModalKey(BUNDLE_DETAILS);
                setBundle(b);
                openModal();
              }}
            />
          ))}
        </div>
      ) : (
        <div className="api-key-management-page__bundles-placeholder">
          {!loading ? (
            <>
              <div className="no-active-keys__image-container">
                <img
                  src={NoBundles}
                  alt="No active keys"
                  className={"no-active-keys__image"}
                />
              </div>
              <div className="no-active-keys__top-text">
                Your API keys are inactive!
              </div>
              <div className="no-bundles__bottom-text">
                Please activate your API keys in the table below before
                subscribing/unsubscribing
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

const ApplicationList = props => {
  const {
    applicationData,
    openModal,
    setModalKey,
    setApplication,
    openDeleteModal
  } = props;
  const loading = useSelector(getLoadingStatus);
  const activeAppCount = applicationData.filter(app => app.active).length;
  const showNewButton = activeAppCount < API_KEY_LIMIT;

  return (
    <div>
      <div className="api-key-management-page__application-list-title">
        <div className="api-key-management-page__section-title">
          Application List
        </div>
        {showNewButton && (
          <GenerateNewKeyButton
            onClick={() => {
              setModalKey(NEW_API_KEY);
              openModal();
            }}
          />
        )}
        {!showNewButton && (
          <ConfirmButton
            label={
              <FlexDiv style={{ alignItems: "center" }}>
                <FiSlash size={15} style={{ marginRight: 10 }} />
                <span>Active API Key Limit Reached</span>
              </FlexDiv>
            }
            style={{ fontSize: 14, height: 32 }}
            disable={true}
          />
        )}
      </div>
      <div className="api-key-management-page__application-list-table">
        {loading && (
          <div className="api-key-management-page__application-list-loader">
            <ClipLoader loading={true} size={40} color={colors.blue} />
          </div>
        )}
        <ApplicationTable
          data={applicationData}
          setApplication={setApplication}
          openDeleteModal={openDeleteModal}
          openModal={modalKey => {
            setModalKey(modalKey);
            openModal();
          }}
        />
      </div>
    </div>
  );
};

const NoSubscribedBundles = ({ setModalKey, openModal }) => {
  return (
    <div className="no-bundles">
      <div className="no-bundles__top-toolbar">
        <ViewDocumentationButton />
      </div>
      <div className="no-bundles__content">
        <div className="no-bundles__image-container">
          <img
            src={NoBundles}
            alt="No subscribed bundle"
            className={"no-bundles__image"}
          />
        </div>
        <div className="no-bundles__top-text">
          You haven't subscribed to any bundles yet!
        </div>
        <div className="no-bundles__bottom-text">
          Click on the button below to generate an API key and start subscribing
          to bundles.
        </div>
        <GenerateNewKeyButton
          onClick={() => {
            setModalKey(NEW_API_KEY);
            openModal();
          }}
        />
      </div>
    </div>
  );
};

const ApiKeyManagementPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalKey, setModalKey] = useState(null);
  const [bundle, setBundle] = useState(null);
  const [selectedApplication, setSelectedApplication] = useState({});
  useEffect(() => {
    if (!showModal) {
      setModalKey(null);
      setBundle(null);
    }
  }, [showModal]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchApplicationList());
    dispatch(fetchApiBundles());
  }, [dispatch]);
  const data = useSelector(getApplicationList);
  const bundles = useSelector(getApiBundles);
  const message = useSelector(getMessage);
  const enableSave = useSelector(getEnableSaveStatus);

  useEffect(() => {
    if (message) {
      if (message.messageType === "success") {
        toast.success(message.text);
      } else if (message.messageType === "error") {
        toast.error(message.text);
      }

      dispatch(clearMessage());
    }
  }, [dispatch, message]);

  const formattedApplications =
    !!data & !!bundles
      ? data.map(application => {
          const relatedBundles = bundles.filter(b =>
            application.apiBundles.includes(b.id)
          );
          return {
            ...application,
            apiBundles: relatedBundles
          };
        })
      : [];

  const formattedBundles = !!bundles
    ? bundles.map(bundle => {
        const relatedApplications = !!data
          ? data.filter(app => app.apiBundles.includes(bundle.id))
          : [];
        const activeItems = relatedApplications.filter(app => app.active);
        const inactiveItems = relatedApplications.filter(app => !app.active);
        return {
          ...bundle,
          activeItems,
          inactiveItems
        };
      })
    : [];

  const payload = useSelector(ApiKeyManagementState.selectors.getFormData);
  const updateHandler = () => {
    const { apiKey } = selectedApplication;
    dispatch(
      ApiKeyManagementState.actionCreators.updateApplication(apiKey, payload)
    );
  };

  const newKeyHandler = () => {
    dispatch(ApiKeyManagementState.actionCreators.createNewApiKey(payload));
  };

  const deleteApiConsumerHandler = () => {
    const { apiKey } = selectedApplication;
    setShowDeleteModal(false);
    dispatch(ApiKeyManagementState.actionCreators.deleteApiConsumer(apiKey));
  };

  const saveHandler = () => {
    switch (modalKey) {
      case EDIT_APPLICATION:
        updateHandler();
        break;
      case NEW_API_KEY:
        newKeyHandler();
        break;
      default:
        return null;
    }
  };

  const showPlaceholder = !!data && formattedApplications.length === 0;

  if (!bundles || !data) {
    return null;
  }

  return (
    <>
      {showPlaceholder ? (
        <NoSubscribedBundles
          setModalKey={setModalKey}
          openModal={() => {
            setShowModal(true);
          }}
        />
      ) : (
        <div className="api-key-management-page">
          <ApiBundle
            loading={!bundles}
            bundles={formattedBundles}
            setBundle={setBundle}
            setModalKey={setModalKey}
            selectedBundle={bundle}
            openModal={() => {
              setShowModal(true);
            }}
          />
          <ApplicationList
            applicationData={formattedApplications}
            setModalKey={setModalKey}
            setApplication={setSelectedApplication}
            openModal={() => {
              setShowModal(true);
            }}
            openDeleteModal={() => setShowDeleteModal(true)}
          />
        </div>
      )}
      <SideDrawer
        show={showModal}
        onSave={saveHandler}
        onEdit={() => setModalKey(EDIT_APPLICATION)}
        setShow={setShowModal}
        title={modalTitles[modalKey]}
        footerType={footerTypeMapping[modalKey]}
        disableSave={!enableSave}
      >
        {modalKey === NEW_API_KEY && <NewApiKeyForm />}
        {modalKey === EDIT_APPLICATION && (
          <EditApplicationRecordForm applicationDetails={selectedApplication} />
        )}
        {modalKey === BUNDLE_DETAILS && <BundleDetailsForm bundle={bundle} />}
        {modalKey === APPLICATION_DETAILS && (
          <ApplicationDetailsForm applicationDetails={selectedApplication} />
        )}
      </SideDrawer>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={deleteApiConsumerHandler}
        applicationDetails={selectedApplication}
      />
      <ToastContainer position="bottom-left" />
    </>
  );
};

export default ApiKeyManagementPage;
