/* eslint-disable import/no-anonymous-default-export */
export default {
  textBlack: "#21252A",
  borderGrey: "#cecece",
  successGreen: "#24A148",
  blue: "#0E65E5",
  selectedBlue: "#CEE0F9",
  dijonYellow: "rgb(248, 211, 103)",
  toolbarGrey: "#707883",
  mediumGrey: "#868E96",
  red: "rgb(201, 53, 50)",
  watermelonRed: "#eb5364",
  backgroundBlue: "rgb(237, 242, 251)",
  disableGrey: "#efefef",
  cancelGrey: "#dfe2e6",
  alternatingRowGrey: "#f8f9fa",
  textGrey: "#a5a5a7",
  footerGrey: "#f9f9f9",
  splashPurple: "#464d96",
  splashBlue: "#4e9ae8",
  splashFooterLightGrey: "#e6e6e6",
  splashFooterDarkGrey: "#808080",
  splashGrey: "#f5f5f5"
};
