import { useSelector } from "react-redux";
import Checkbox from "../../components/Checkbox";
import ApiKeyManagementState from "./ApiKeyManagementState";
import { FiInfo } from "react-icons/fi";
import "./apiBundles.scss";

const { getApiBundles } = ApiKeyManagementState.selectors;

const ApiBundleRow = props => {
  const { label, even, selected, onSelect, onDeselect, disabled } = props;
  return (
    <div
      className={even ? "api-bundle-list__row--even" : "api-bundle-list__row"}
    >
      <Checkbox
        isSelected={selected}
        disabled={disabled}
        onClick={() => {
          if (selected) {
            onDeselect();
          } else {
            onSelect();
          }
        }}
        size={20}
      />
      <span className="api-bundle-list__row-label">{label}</span>
    </div>
  );
};

const apiBundleHoverInfo = "Minimum 1 bundle is required to create an API key";

const ApiBundles = props => {
  const { apiBundles, style, updateSelectedBundles, disabled } = props;
  const bundles = useSelector(getApiBundles);
  return (
    <div>
      <div
        className="api-bundle-list__title"
        style={{
          ...style
        }}
      >
        <span>API Bundles</span>
        <div className="api-bundle-list__info">
          <FiInfo size={14} />
          <span className="api-bundle-list__info-text">
            {apiBundleHoverInfo}
          </span>
        </div>
      </div>
      {bundles.map((b, i) => (
        <ApiBundleRow
          key={b.id}
          even={i % 2 === 0}
          disabled={disabled}
          label={b.name}
          selected={apiBundles.includes(b.id)}
          onSelect={() => updateSelectedBundles([...apiBundles, b.id])}
          onDeselect={() =>
            updateSelectedBundles(apiBundles.filter(bundle => bundle !== b.id))
          }
        />
      ))}
    </div>
  );
};

export default ApiBundles;
