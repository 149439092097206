import { Modal } from "rsuite";
import "./smallModal.css";
import { ConfirmButton } from "./Buttons";
import { FlexDiv } from "./FlexDiv";
import colors from "../colors";

const buttonStyles = {
  padding: "0px 10px"
};

const SmallModal = props => {
  const { show, children, title, onHide, onConfirm } = props;
  return (
    <Modal show={show} size="xs" onHide={onHide} classPrefix="custom-modal">
      <Modal.Header classPrefix="custom-modal-header">{title}</Modal.Header>
      {children}
      <Modal.Footer classPrefix="custom-modal-footer">
        <FlexDiv
          style={{
            justifyContent: "flex-end"
          }}
        >
          <ConfirmButton
            label={"Cancel"}
            onClick={onHide}
            style={{
              marginRight: 10,
              backgroundColor: colors.cancelGrey,
              color: "black",
              ...buttonStyles
            }}
          />
          <ConfirmButton
            onClick={onConfirm}
            label={"Confirm Delete"}
            style={{ backgroundColor: colors.red, ...buttonStyles }}
          />
        </FlexDiv>
      </Modal.Footer>
    </Modal>
  );
};

export default SmallModal;
